import {useCallback, useState} from "react";
import { useAuth } from "./UseAuth";

export default function useFetching(callback) {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState({errors: false, message: ''})
    const {signout} = useAuth()
    const fetching = useCallback(async (param = undefined) => {
        setIsLoading(true)
        let response
        try {
            response = await callback(param)
            if (response.status == 403){
                signout()
            } 
        } catch (e) {
            setIsError({errors: true, message: "Ошибка получения ответа."});
        }
        setIsLoading(false);
        return response
    }, [callback])
    return [fetching, isLoading, isError]
}
