import {TOKEN} from "../consts/storage";

class Network {
    constructor() {
        this.config = {
            baseURL: 'https://scisearch.ru/api/',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        this.request = this.request.bind(this);
        this.searchRequest = this.searchRequest.bind(this);
        this.requestNoBody = this.requestNoBody.bind(this);
        this.get = this.get.bind(this);
        this.put = this.put.bind(this);
        this.search = this.search.bind(this)
        this.post = this.post.bind(this);
        this.delete = this.delete.bind(this);
        this.deleteNoBody = this.deleteNoBody.bind(this);
    }


    createURL(url,token=true) {
        let newUrl = this.config.baseURL + url
        if (token && localStorage.getItem(TOKEN)) {
            newUrl += url.includes('?') ? '&' : '?'
            newUrl += `access_token=${localStorage.getItem(TOKEN)}`
        }
        return newUrl
    }
    async searchRequest(url, method){
        const response = await fetch(this.createURL(url,false), {
            headers: this.config.headers,
            method,
        })
        const data = await response.json()
        return {
            data: data,
            status: response.status,
            ok: response.ok
        }
    }
    async request(url, method, body) {
        const response = await fetch(this.createURL(url), {
                headers: this.config.headers,
                method,
                body: JSON.stringify({...body})
        })
        const data = await response.json()
        return {
            data: data,
            status: response.status,
            ok: response.ok
        }
    }

    async requestNoBody(url, method) {
        const response = await fetch(this.createURL(url), {
            headers: this.config.headers,
            method,
        })
        const data = await response.json()
        return {
            data: data,
            status: response.status,
            ok: response.ok
        }
    }

    async get(url) {
        return await this.requestNoBody(url, 'GET')
    }
    async put(url, body) {
        return await this.request(url, 'PUT', body)
    }
    async search(url){
        return await this.searchRequest(url, 'POST')
    }
    async post(url, body) {
        return await this.request(url, 'POST', body)
    }

    async delete(url, body) {
        return await this.request(url, 'DELETE', body)
    }

    async deleteNoBody(url) {
        return await this.requestNoBody(url, 'DELETE')
    }
}

const SciNetwork = new Network()
export default SciNetwork
