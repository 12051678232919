import { Modal, ModalBody } from "react-bootstrap";
import "./ModalApprove.css"

const ModalApprove = ({text,show,setShow,deleteFunction,showModal}) => {
    return (
        <>
        <Modal  show={show} centered onHide={()=>{setShow(false)}}>
            <ModalBody >
                <div className="text-center w-100 my-4 fs-4" style={{color:"#1731ac"}}>{text}</div>
                <div className="d-flex justify-content-center">
                    <button className="cancel shadow-sm py-2" onClick={showModal}>Нет</button>
                    <button className="approve py-2" onClick={deleteFunction}>Да</button>
                </div>
            </ModalBody>
        </Modal>
        
        </>
    );
};

export default ModalApprove;