import SciNetwork from "../config/httpConfig";
import { USER_ID } from "../consts/storage";

export default class UserApi {
    static async register(email,password,name,surname,middlename) {
        return await SciNetwork.post('auth/register',{
            email: email,
            password: password,
            name: name,
            surname: surname,
            middlename: middlename
        })
    }
    static async login(email,password){
        return await SciNetwork.post('auth/login',{
            email: email,
            password: password,
        })
    }
    static async getUser(){
        return await SciNetwork.get(`users/${localStorage.getItem(USER_ID)}`)
    }
    static async changeUser(surname,name,middlename,interests){
        return await SciNetwork.put(`users/${localStorage.getItem(USER_ID)}`,
        {
            "name": name,
            "surname": surname,
            "middlename": middlename,
            "interests": interests
        }
        )
    }
    static async deleteUser(){
        return await SciNetwork.delete(`users/${localStorage.getItem(USER_ID)}`)
    }
}

