import { ArticlesPage } from "../pages/ArticlesPage"
import {AuthPage} from "../pages/AuthPage"
import { MainPage } from "../pages/MainPage"
import { ProfilePage } from "../pages/ProfilePage"
import { RegistrationPage } from "../pages/RegistrationPage"
import { SearchPage } from "../pages/SearchPage"
import { SelectionPage } from "../pages/SelectionPage"
import { ARTICLES, AUTH, MAIN, PROFILE, REG, SEARCH, SELECTION } from "./consts"

export const authRoutes = [
    {
        element: <ProfilePage />,
        path: PROFILE
    },
    {
        element: <SearchPage />,
        path: SEARCH
    },
    {
        element: <SelectionPage />,
        path: SELECTION
    }
]
export const publicRoutes = [
    {
        element: <AuthPage />,
        path: AUTH
    },
    {
        element: <RegistrationPage />,
        path: REG
    },
    {
        element: <MainPage />,
        path: MAIN
    }
]