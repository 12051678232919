import React, { useState } from 'react';
import "./Fallback.css";
import { TOKEN } from '../../shared/consts/storage';
import Topbar from '../../features/TopBar/Topbar';
import { Sidebar } from '../../features/Sidebar';
import {ReactComponent as Loader} from "../../shared/svg/three-dots.svg"

const Fallback = () => {
    const [full,setFull] = useState(false)
    return(
        <div style={{width:"100%",height:"100vh"}}>
            {localStorage.getItem(TOKEN)===null ?
                <Loader className="m-auto na-fallback"/>
            :
            <>
                <Topbar full={full} setFull={setFull}/>
                <Sidebar full={full} setFull={setFull}/>
                <Loader className="auth-fallback mx-auto"/>
            </>
            }
        </div>
    )
};

export default Fallback;