import { Col, Image, Row } from "react-bootstrap"
import "./Sidebar.css"
import {ReactComponent as List} from "../../../shared/svg/list.svg"
import {ReactComponent as Search} from "../../../shared/svg/search.svg"
import {ReactComponent as Book} from "../../../shared/svg/book.svg"
import {ReactComponent as Person} from "../../../shared/svg/person.svg"
import {ReactComponent as WSearch} from "../../../shared/svg/cil_search.svg"
import {ReactComponent as WBook} from "../../../shared/svg/w-book.svg"
import {ReactComponent as WPerson} from "../../../shared/svg/w-per.svg"
import {ReactComponent as X} from "../../../shared/svg/x.svg"
import logo from "../../../shared/images/logo.png"
import { useAuth } from "../../../shared/hooks/UseAuth"
import { PROFILE, SEARCH, SELECTION } from "../../../Router/consts"
import { useNavigate } from "react-router-dom"

const Sidebar = ({full,setFull})=>{
    const {signout,page} = useAuth()
    const navigate = useNavigate()
    return(
        <>
        {full ? 
            <Col className="sidebar-full shadow" >
                <div className="d-flex w-100 justify-content-end"><X  className="sidebar-x cpointer" fill="#1731AC" onClick={()=>setFull(!full)}/></div>
                <Row className="px-3 sidebar-logo"><Image className="sidebar-logo" src={logo}/></Row>
                <div className="navigation w-100"> 
                    {page === PROFILE ?
                        <div className="span-active d-flex w-100">
                                <div className="svg">
                                    <WPerson />
                                </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                                Личный кабинет
                            </div>
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>{setFull(false);navigate(PROFILE)}} >
                            <div className="svg">
                                <Person />
                            </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                            Личный кабинет
                            </div>
                        </div>   
                    }
                    {page === SELECTION ?
                        <div className="span-active d-flex w-100" >
                                <div className="svg">
                                    <WBook />
                                </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                                Подборки
                            </div>
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>{setFull(false);navigate(SELECTION)}}>
                            <div className="svg">
                                <Book />
                            </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                            Подборки
                            </div>
                        </div>   
                    }
                    {page === SEARCH ?
                        <div className="span-active d-flex w-100" >
                                <div className="svg">
                                    <WSearch  />
                                </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                                Поиск
                            </div>
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>{setFull(false);navigate(SEARCH)}} >
                            <div className="svg">
                                <Search />
                            </div>
                            <div className="d-flex align-items-center mx-3 cpointer" >
                           Поиск
                            </div>
                        </div>   
                    }
                </div>
            </Col>
        :
            <div className="sidebar shadow" >
                <List className="sidebar-list" onClick={()=>setFull(!full)} width="40px" height="40px"/>
                <div className="navigation w-100">
                {page === PROFILE ?
                        <div className="span-active d-flex w-100" onClick={()=>navigate(PROFILE)}>
                                <div className="svg">
                                    <WPerson />
                                </div>
                                <div className="span-hover">
                                    Личный кабинет
                                </div>
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>navigate(PROFILE)} >
                            <div className="svg">
                                <Person />
                            </div>
                            <div className="span-hover">
                                    Личный кабинет
                            </div>
                           
                        </div>   
                    }
                    {page === SELECTION ?
                        <div className="span-active d-flex w-100" onClick={()=>navigate(SELECTION)} >
                                <div className="svg">
                                    <WBook />
                                </div>
                                <div className="span-hover">
                                    Подборки
                                </div>
                            
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>navigate(SELECTION)}>
                            <div className="svg">
                                <Book />
                            </div>
                            <div className="span-hover">
                                    Подборки
                            </div>
                        </div>   
                    }
                    {page === SEARCH ?
                        <div className="span-active d-flex w-100" onClick={()=>navigate(SEARCH)}>
                                <div className="svg">
                                    <WSearch  />
                                </div>
                                <div className="span-hover">
                                    Поиск
                                </div>
                            
                        </div>  
                            :
                        <div className="span d-flex w-100" onClick={()=>navigate(SEARCH)} >
                            <div className="svg">
                                <Search />
                            </div>
                            <div className="span-hover">
                                    Поиск
                            </div>
                        </div>   
                    }
                </div>    
            </div>
        }
        </>
    )
}
export default Sidebar;