import "./Topbar.css"
import {ReactComponent as Logo} from "../../shared/svg/sm-logo.svg"
import {ReactComponent as Exit} from "../../shared/svg/exit-blue.svg"
import {ReactComponent as List} from "../../shared/svg/list.svg"
import { useNavigate } from "react-router-dom"
import { PROFILE } from "../../Router/consts"
import { useAuth } from "../../shared/hooks/UseAuth"
import {  useEffect, useState } from "react"
import useFetching from "../../shared/hooks/UseFetching"
import UserApi from "../../shared/api/UserApi"
import ModalApprove from "../../components/ModalApprove/ModalApprove"
const Topbar = ({full,setFull}) =>{
    const navigate = useNavigate()
    const {signout} = useAuth()
    const exit =()=>{
        signout()
    }
    const [name,setName] = useState('')
    const [surname,setSurname] = useState('')
    const [getUser] = useFetching(async()=>{
        return await UserApi.getUser()
    })
    const [showModal,setShowModal] = useState(false)
    const handleShowModal = () => setShowModal(true)
    useEffect(()=>{
        getUser().then(res=>{
            if(!res){
                return
            }
            if(!res.ok){
                signout()
                return
            }
            setName(res.data.user.name)
            setSurname(res.data.user.surname)

        })    
    },[])
    return(
    <div className="topbar shadow d-flex align-items-center justify-content-end">
        <List className="lst-svg"   onClick={()=>setFull(!full)}/>
        <Logo className="top-logo"/>
            <p className="name p-0 my-0 "
                onClick={()=>navigate(PROFILE)}
            >{name} {surname}</p>
            <Exit className="cp exit-top" onClick={handleShowModal}/>
            <ModalApprove text="Вы действительно хотите выйти из аккаунта?" show={showModal} showModal={()=>setShowModal(!showModal)} setShow={setShowModal} deleteFunction={exit}/>
    </div>
    )
}
export default Topbar;