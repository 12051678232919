import { createContext, useState } from "react";
import { TOKEN } from "../../shared/consts/storage";

export const AuthContext = createContext(null)
export const AuthProvider = ({children}) =>{
    const [isAuth,setAuth] = useState(localStorage.getItem(TOKEN)!==null)
    const [page,setPage] = useState('')
    const signin = () =>{
        setAuth(true)
    }
    const signout = () =>{
        setAuth(false)
        localStorage.clear()
        sessionStorage.clear()
    }
    const value = {isAuth,page,setPage,signin,signout}
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}