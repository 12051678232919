import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ARTICLES, MAIN} from "./consts";
import ReaquiredAuth from "./hox/RequiredAuth";
import NotAuth from "./hox/NotAuth";
import { authRoutes, publicRoutes } from "./Routes";
import { ArticlesPage } from "../pages/ArticlesPage";
import NFpage from "../pages/NFpage/NFpage";
import Fallback from "../pages/Fallback/Fallback";

const AppRouter = ()=>{
    return(
         <Suspense fallback={<Fallback />}>
            <Routes>
                {authRoutes.map(({path,element}) => (
                        <Route key={path} path={path} element={<ReaquiredAuth>{element}</ReaquiredAuth>} />
                ))}
                <Route path={ARTICLES + '/:selectionId'} element={<ReaquiredAuth><ArticlesPage /></ReaquiredAuth>} />
                {publicRoutes.map(({path,element}) => (
                    <Route key={path} path={path} element={<NotAuth>{element}</NotAuth>} />
                ))} 
                <Route path="*" element={<NFpage />} />
            </Routes> 
        </Suspense>
       
    )
}
export default AppRouter;