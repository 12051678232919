import { Image } from "react-bootstrap";
import nf from  "../../shared/images/404.png"
import "./NFpage.css"
import {ReactComponent as NFlogo} from "../../shared/svg/sm-logo.svg"
import { TOKEN } from "../../shared/consts/storage";
import { AUTH, MAIN } from "../../Router/consts";
import { Link } from "react-router-dom";
import Topbar from "../../features/TopBar/Topbar";
import { Sidebar } from "../../features/Sidebar";
import { useState } from "react";
const NFpage = ()=>{
    const [full,setFull] = useState(false)
    return(
        <div className="nf-page">
            {localStorage.getItem(TOKEN)===null ?
            <div className="d-flex justify-content-between w-100" style={{position:"absolute",top:"0"}}>
                <NFlogo className="nf-logo"/>
                <div className="d-flex align-items-center" >
                    <Link className="nf-link mx-4" to={MAIN}>Поиск</Link>
                    <Link className="nf-link mx-4" to={AUTH}>Войти</Link>
                </div>
            </div>
            :
            <>
                <Topbar full={full} setFull={setFull}/>
                <Sidebar full={full} setFull={setFull}/>
            </>
            }
                <div className="my-auto mx-auto error" >
                    <Image src={nf} className="nf-image"/>
                    <p className="nf-text my-0 w-auto">Кажется, что-то пошло не так!</p>
                    <p className="nf-text">Страница не найдена.</p>
                </div>
        </div>
    )
}
export default NFpage;